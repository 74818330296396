<template>
  <v-container fluid>
    <v-card elevation="4" class="mt-0">
      <v-tabs v-model="tab">
        <v-tab v-for="item in items" :key="item.tab">
          {{ item.tab }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.tab">
          <main-tab v-if="item.content == 'main'"></main-tab>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {
    MainTab: () => import("./roles-components/MainTab.vue"),
  },

  async mounted() {
    try {
      await this.$store.dispatch("roles/list");
      await this.$store.dispatch("permissions/list");
    } catch (error) {
      if (error.response?.status == "401") {
        this.$store.dispatch("logout");
      }
      this.$store.dispatch("alerts/error", error.response?.data?.message);
    }
  },

  data() {
    return {
      tab: null,
      items: [
        {
          tab: this.$t("main"),
          content: "main",
        },
      ],
    };
  },
};
</script>
